// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Footer/Footer.module.css */

.Style_footer__YzJzP {
  background-color:black;
  color: white;
  padding: 50px  0 ;
  margin-top: 150px;
}

.Style_container__VyrZv {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.Style_logoContainer__VWcch {
  margin-bottom: 20px;
}

.Style_logo__jz139 {
  width: 150px;
}

.Style_row__MyyNJ {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.Style_column__2DzN9 {
  flex: 1 1;
  padding: 10px;
  min-width: 200px; /* Ensure columns stack properly on small screens */
}

.Style_column__2DzN9 h4 {
  margin-bottom: 10px;
}

.Style_column__2DzN9 p, .Style_column__2DzN9 a {
  margin-bottom: 5px;
  color: white;
  text-decoration: none;
}

.Style_column__2DzN9 a:hover {
  text-decoration: underline;
}

.Style_icon__HipGx {
  margin-right: 10px;
  color: #ccc;
  font-size: 2em;
}

.Style_icon__HipGx:hover {
  color: white;
}
.Style_scrollToTop__pUJqo {
  bottom: 20px;
  right: 20px;
  background: #333;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.Style_scrollToTop__pUJqo:hover {
  background: #555;
}

.Style_scrollIcon__p088P {
  font-size: 3em;
}`, "",{"version":3,"sources":["webpack://./src/Footer/Style.module.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;EACE,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;AAErB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,gBAAgB,EAAE,mDAAmD;AACvE;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* src/Footer/Footer.module.css */\n\n.footer {\n  background-color:black;\n  color: white;\n  padding: 50px  0 ;\n  margin-top: 150px;\n}\n\n.container {\n  width: 80%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n}\n\n.logoContainer {\n  margin-bottom: 20px;\n}\n\n.logo {\n  width: 150px;\n}\n\n.row {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  flex-wrap: wrap;\n}\n\n.column {\n  flex: 1;\n  padding: 10px;\n  min-width: 200px; /* Ensure columns stack properly on small screens */\n}\n\n.column h4 {\n  margin-bottom: 10px;\n}\n\n.column p, .column a {\n  margin-bottom: 5px;\n  color: white;\n  text-decoration: none;\n}\n\n.column a:hover {\n  text-decoration: underline;\n}\n\n.icon {\n  margin-right: 10px;\n  color: #ccc;\n  font-size: 2em;\n}\n\n.icon:hover {\n  color: white;\n}\n.scrollToTop {\n  bottom: 20px;\n  right: 20px;\n  background: #333;\n  color: white;\n  padding: 10px;\n  border-radius: 50%;\n  cursor: pointer;\n  transition: background 0.3s;\n}\n\n.scrollToTop:hover {\n  background: #555;\n}\n\n.scrollIcon {\n  font-size: 3em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Style_footer__YzJzP`,
	"container": `Style_container__VyrZv`,
	"logoContainer": `Style_logoContainer__VWcch`,
	"logo": `Style_logo__jz139`,
	"row": `Style_row__MyyNJ`,
	"column": `Style_column__2DzN9`,
	"icon": `Style_icon__HipGx`,
	"scrollToTop": `Style_scrollToTop__pUJqo`,
	"scrollIcon": `Style_scrollIcon__p088P`
};
export default ___CSS_LOADER_EXPORT___;
