import React from "react";
import Contact from "../Contact/Contact";
import NavBare from "../Header2/Navbare"
import Footer from "../Footer/Footer";
export default function ContactPage() {
  return (
    <div>
        <NavBare />
        <Contact />
        <Footer />
    </div>
  );
}
