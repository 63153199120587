// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollToTop {
  bottom: 20px;
  right: 20px;
  background: #333;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
  width: 50px;
  height:50px;
  position: fixed;
}

.scrollToTop:hover {
  background: #555;
}

.scrollIcon {
  font-size: 2em;
}
.icon {
  margin-right: 10px;
  color: #ccc;
  font-size: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,WAAW;EACX,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;AAChB","sourcesContent":[".scrollToTop {\n  bottom: 20px;\n  right: 20px;\n  background: #333;\n  color: white;\n  padding: 10px;\n  cursor: pointer;\n  transition: background 0.3s;\n  width: 50px;\n  height:50px;\n  position: fixed;\n}\n\n.scrollToTop:hover {\n  background: #555;\n}\n\n.scrollIcon {\n  font-size: 2em;\n}\n.icon {\n  margin-right: 10px;\n  color: #ccc;\n  font-size: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
