// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video{
    width: 50%;
}
@media (max-width: 1600px) {
  .video{
    width: 60% !important;
  }
}
@media (max-width: 1200px) {
  .video{
    width: 45% !important;
  }
}
@media (max-width: 476px) {
  .video{
    width: 70% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/VideoPlayer/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;EACE;IACE,qBAAqB;EACvB;AACF;AACA;EACE;IACE,qBAAqB;EACvB;AACF;AACA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".video{\n    width: 50%;\n}\n@media (max-width: 1600px) {\n  .video{\n    width: 60% !important;\n  }\n}\n@media (max-width: 1200px) {\n  .video{\n    width: 45% !important;\n  }\n}\n@media (max-width: 476px) {\n  .video{\n    width: 70% !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
