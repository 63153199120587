// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_popupOverlay__6e\\+xE {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Popup_popupContent__8icR2 {
  background: #fff;
  padding: 70px; /* Increased padding for a larger popup */
  border-radius: 10px; /* Slightly larger border radius */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 30%; /* Increased width */
  height: auto;
  position: relative; /* Needed for positioning the close button */
}

.Popup_closeButton__Wx99K {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.Popup_sendButton__BDy5a {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50; /* Green background */
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.Popup_sendButton__BDy5a:hover {
  background-color: #45a049; /* Darker green on hover */
}

input{
    padding: 10px 30px;
}
label{
    font-size: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Popup/Popup.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa,EAAE,yCAAyC;EACxD,mBAAmB,EAAE,kCAAkC;EACvD,yCAAyC;EACzC,kBAAkB;EAClB,UAAU,EAAE,oBAAoB;EAChC,YAAY;EACZ,kBAAkB,EAAE,4CAA4C;AAClE;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB,EAAE,qBAAqB;EAChD,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".popupOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.popupContent {\n  background: #fff;\n  padding: 70px; /* Increased padding for a larger popup */\n  border-radius: 10px; /* Slightly larger border radius */\n  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n  text-align: center;\n  width: 30%; /* Increased width */\n  height: auto;\n  position: relative; /* Needed for positioning the close button */\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background: none;\n  border: none;\n  font-size: 1.5em;\n  cursor: pointer;\n}\n\n.sendButton {\n  margin-top: 20px;\n  padding: 10px 20px;\n  border: none;\n  background-color: #4CAF50; /* Green background */\n  color: #fff;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 1em;\n}\n\n.sendButton:hover {\n  background-color: #45a049; /* Darker green on hover */\n}\n\ninput{\n    padding: 10px 30px;\n}\nlabel{\n    font-size: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupOverlay": `Popup_popupOverlay__6e+xE`,
	"popupContent": `Popup_popupContent__8icR2`,
	"closeButton": `Popup_closeButton__Wx99K`,
	"sendButton": `Popup_sendButton__BDy5a`
};
export default ___CSS_LOADER_EXPORT___;
